export default [
  {
    text: '美妆日化',
    img: require('../../images/industry/beautyMarkeUp.png'),
    activeImg: require('../../images/industry/beautyMarkeUp-active.png'),
  },
  {
    text: '图书',
    img: require('../../images/industry/book.png'),
    activeImg: require('../../images/industry/book-active.png'),
  },
  {
    text: '外卖',
    img: require('../../images/industry/takeout.png'),
    activeImg: require('../../images/industry/takeout-active.png'),
  },
  {
    text: '医疗',
    img: require('../../images/industry/medicalTreatment.png'),
    activeImg: require('../../images/industry/medicalTreatment-active.png'),
  },
  {
    text: '运动',
    img: require('../../images/industry/sport.png'),
    activeImg: require('../../images/industry/sport-active.png'),
  },
  {
    text: '餐饮',
    img: require('../../images/industry/diet.png'),
    activeImg: require('../../images/industry/diet-active.png'),
  },
  {
    text: '珠宝',
    img: require('../../images/industry/jewelry.png'),
    activeImg: require('../../images/industry/jewelry-active.png'),
  },
  {
    text: '服饰',
    img: require('../../images/industry/clothes.png'),
    activeImg: require('../../images/industry/clothes-active.png'),
  },
  {
    text: '植物',
    img: require('../../images/industry/plant.png'),
    activeImg: require('../../images/industry/plant-active.png'),
  },
  {
    text: '游戏',
    img: require('../../images/industry/game.png'),
    activeImg: require('../../images/industry/game-active.png'),
  },
  {
    text: '金融',
    img: require('../../images/industry/financial.png'),
    activeImg: require('../../images/industry/financial-active.png'),
  },
  {
    text: '直播',
    img: require('../../images/industry/liveStreaming.png'),
    activeImg: require('../../images/industry/liveStreaming-active.png'),
  },
  {
    text: '团购',
    img: require('../../images/industry/groupbuy.png'),
    activeImg: require('../../images/industry/groupbuy-active.png'),
  },
  {
    text: '社交',
    img: require('../../images/industry/socialContact.png'),
    activeImg: require('../../images/industry/socialContact-active.png'),
  },
  {
    text: '教育',
    img: require('../../images/industry/education.png'),
    activeImg: require('../../images/industry/education-active.png'),
  },
  {
    text: '跑腿',
    img: require('../../images/industry/errand.png'),
    activeImg: require('../../images/industry/errand-active.png'),
  },
  {
    text: '智能产品',
    img: require('../../images/industry/intelligentProduct.png'),
    activeImg: require('../../images/industry/intelligentProduct-active.png'),
  },
  {
    text: '家政',
    img: require('../../images/industry/housekeeping.png'),
    activeImg: require('../../images/industry/housekeeping-active.png'),
  },
  {
    text: '房地产',
    img: require('../../images/industry/realty.png'),
    activeImg: require('../../images/industry/realty-active.png'),
  },
  {
    text: '更多',
    img: require('../../images/industry/more.png'),
    activeImg: require('../../images/industry/more-active.png'),
  },
]
