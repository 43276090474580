<template>
  <div class="app">
    <div class="banner1">
      <img src="../../images/app-banner1.webp" />
      <img class="btn" src="../../images/moreCaseBtn.png" @click="onClickBtn" />
    </div>
    <div class="banner2">
      <img src="../../images/app-banner2.webp" />
    </div>
    <div class="applicationField">
      <div class="header">
        <h2 class="title">APP应用领域</h2>
        <p class="desc">
          我们对各行业的用户调查和市场分析，确保能针对不同行业开发出最适合消费者的APP
        </p>
      </div>
      <div class="industry">
        <div
          class="item"
          :class="{ active: activeIndustryIndex == index }"
          v-for="(item, index) in industrys"
          :key="item.text"
          @mouseenter="onIndustryMouseenter(index)"
          @mouseleave="onIndustryMouseleave(index)"
        >
          <div class="icon">
            <img
              :src="activeIndustryIndex == index ? item.activeImg : item.img"
              alt=""
              srcset=""
            />
          </div>
          <span class="text">{{ item.text }}</span>
        </div>
      </div>
    </div>
    <div class="caseShow">
      <div class="header">
        <h2 class="title">案列展示</h2>
      </div>
      <m-case-box :navId="navId" />
    </div>
    <investment></investment>
  </div>
</template>
<script>
import industrys from './industry'
import mCaseBox from '@/components/mCaseBox'
import investment from '@/components/investment'
export default {
  components: {
    mCaseBox,
    investment,
  },
  data() {
    return {
      industrys,
      activeIndustryIndex: -1,
    }
  },
  created() {
    this.navId = this.$route.params.navId
  },
  methods: {
    onIndustryMouseenter(index) {
      this.activeIndustryIndex = index
    },
    onIndustryMouseleave() {
      this.activeIndustryIndex = -1
    },
    onClickBtn() {
      this.$router.push({ name: 'case', query: { navId: '' } })
    },
  },
}
</script>

<style lang="scss">
.app {
  .banner1,
  .banner2 {
    width: 100%;
  }
  .banner1 {
    position: relative;
    overflow: hidden;
    img {
      transition: all 0.5s;
    }
    img:hover {
      transform: scale(1.05);
    }
    .btn {
      cursor: pointer;
      position: absolute;
      width: 14%;
      bottom: 9%;
      right: 15%;
    }
  }

  .applicationField {
    padding: 50px 0 34px;
    margin: 0 auto;
    .header {
      .title {
        font-size: 44px;
        font-weight: bold;
        color: #383838;
        line-height: 54px;
        padding-bottom: 6px;
      }
      .desc {
        font-size: 16px;
        line-height: 26px;
        color: #383838;
      }
    }
    .industry {
      width: 940px;
      margin: 45px auto 0;
      text-align: left;
      .item {
        width: 166px;
        height: 124px;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f2f2f2;
        margin: 0 11px 24px;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.5s;
        &.active {
          background-color: #f5c02f;
          .text {
            color: #fff;
          }
        }
        .text {
          font-size: 16px;
          font-weight: 700;
          color: #565656;
          margin-top: 10px;
        }
      }
    }
  }
  .caseShow {
    padding: 25px 0 58px;
    background-color: #f4f5f9;
    .header {
      padding-bottom: 30px;
      .title {
        font-size: 44px;
        font-weight: bold;
        color: #383838;
        line-height: 54px;
        padding-bottom: 6px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 56px;
          height: 2px;
          background: #373737;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%);
        }
      }
    }
  }
}
</style>
